<template>
  <!-- :height="$vuetify.breakpoint.height > 650 ? '60vh' : '50vh'" -->
  <v-data-table
    class="elevation-5 ma-0 main-table rounded-0 table-custom-header"
    dense
    fixed-header
    hide-default-footer
    :expanded.sync="expandedData"
    height="70vh"
    :header-props="{ sortIcon: 'mdi-chevron-up' }"
    :headers="headerTable"
    :items="tabTitle === 'On Hand' ? inventoryDataFilteredSellStatus : items"
    :loading="tableIsLoading"
    :server-items-length="totalItems"
    @update:options="dispatchSortColumn"
  >
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <slot name="expandedTable" :item="item" />
      </td>
    </template>
    <template v-for="(header, index) in headerTable" v-slot:[`header.${header.value}`]>
      <div :key="header.value">
        <v-checkbox
          v-if="header.value === 'All'"
          class="pa-0 ma-0 checkbox"
          color="background"
          :input-value="flagAllSelect"
          hide-details
          @click="flagAllChange"
        ></v-checkbox>

        <div
          v-else
          @click.ctrl="switchColumns && selectHeader(index)"
          :class="[
            selectedHeader.first === `${index}` || selectedHeader.last == `${index}`
              ? 'selected-header'
              : '',
            header.multiSortEffect ? 'multiSortEffect' : '',
          ]"
        >
          <span>{{ header.text }}</span>
        </div>
      </div>
    </template>

    <template v-slot:item="{ item, index }">
      <tr
        class="transparent"
        @click.ctrl="
          isNotSOLDOrEXPIRE(item) && !item.newItem && clickRow(item, index, $event)
        "
        @click.shift="isNotSOLDOrEXPIRE(item) && !item.newItem && multiCheckRow(index)"
      >
        <td
          v-for="(header, key) in headerTable"
          :key="key"
          :class="item.select ? '' : 'dark-blue'"
        >
          <RowStatic
            v-if="
              header.value === 'All' ||
              header.value === 'EquipmentID' ||
              header.value === 'isFlaggedEnabled'
            "
            :header="header"
            :item="item"
            :index="index"
            :items="items"
            :expandedData="expandedData"
            :expandedTable="expandedTable"
            :filters="filters"
            @itemChanges="itemChanges"
            @clickColumn="clickColumn"
            @checkRow="checkRow"
          />

          <span v-else-if="!item.select" class="ma-0 rounded-0">
            {{ item[header.value] }}</span
          >
          <Row
            v-else
            :header="header"
            :item="item"
            :index="index"
            :items="items"
            :expandedData="expandedData"
            :expandedTable="expandedTable"
            :filters="filters"
            @itemChanges="itemChanges"
            @clickColumn="clickColumn"
            @checkRow="checkRow"
          />
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import { table_mixin } from "@/plugins/mixin-inventory.js";
import Row from "@/components/Inventory/Grid/Table/Row.vue";
import RowStatic from "@/components/Inventory/Grid/Table/RowStatic.vue";
import { DATES, ENABLE_DATES } from "@/helpers/mocks/dates.js";

import _ from "lodash";

export default {
  components: {
    Row,
    RowStatic,
  },
  mixins: [table_mixin],
  created() {
    this.generateHeader();
  },
  mounted() {
    this.flagAllSelect = false;
  },
  props: ["items", "header", "switchColumns", "expandedTable", "tabTitle", "filters"],
  data() {
    return {
      selectedHeader: {
        //to switch the column
        first: "",
        last: "",
      },
      headerTable: [],
      expandedData: [],
      ENABLE_DATES,
      DATES,
      // lastCheckIndex: null,
      flagAllSelect: false,
      height: "20%",
      dialog: false
    };
  },
  computed: {
    ...mapState([
      "tableIsLoading",
      "changedItems",
      "totalItems",
      "inventoryDataFiltered"
    ]),
    ...mapState({
      selectedRows: (state) => state.moduleInventory.selectedRows,
      lastCheckIndexVuex: (state) => state.moduleInventory.lastCheckIndex,
      equipmentsCopy: (state) => state.moduleInventory.equipmentsCopy,
    }),
    lastCheckIndex() {
      return this.lastCheckIndexVuex[this.tabTitle];
    },
    inventoryDataFilteredSellStatus() {
      return this.items.filter((item) => item.Status === "SELL" || item.Status === "1");
    },
  },
  watch: {
    header() {
      this.generateHeader();
    },
  },
  methods: {
    //header
    ...mapMutations([
      "addChangedItem",
      "removeChangeItem",
      "moduleInventory/addRowSelect",
      "moduleInventory/removeRowSelect",
      "moduleInventory/setSelectedRows",
      "moduleInventory/setLastCheckIndex",
      "moduleInventory/setEquipmentsCopy",
    ]),

    ...mapActions(["sortColumn", "moduleInventory/showInboundStatus"]),
    flagAllChange() {
      this["moduleInventory/setLastCheckIndex"]({
        name: this.tabTitle,
        value: 0,
      });
      this.flagAllSelect = !this.flagAllSelect;
      let newSelectedRows = [];
      this.items.map((item) => {
        if (this.isNotSOLDOrEXPIRE(item)) {
          item["select"] = this.flagAllSelect;
          if (this.flagAllSelect) {
            newSelectedRows.push(item.id);
          }
        }
      });

      this["moduleInventory/setSelectedRows"](newSelectedRows);
    },
    clickRow(item, index, event) {
      if (
        event.target.previousSibling === null ||
        event.target.previousSibling.id !== "checkbox"
      ) {
        this.flagAllSelect = false;

        this["moduleInventory/setLastCheckIndex"]({
          name: this.tabTitle,
          value: index,
        });

        item.select = !item.select;
        if (!item.newItem) {
          if (item.select) {
            this["moduleInventory/addRowSelect"](item.id);
          } else {
            let index = this.selectedRows.indexOf(item.id);
            if (index >= 0) this["moduleInventory/removeRowSelect"](index);
          }
        }
      }
    },
    async checkRow({ item, index, event }) {
      if (!event || !event.shiftKey) {
        this.flagAllSelect = false;

        item.select = !item.select;

        this["moduleInventory/setLastCheckIndex"]({
          name: this.tabTitle,
          value: index,
        });

        if (!item.newItem) {
          if (item.select) {
            console.log(item);
            this["moduleInventory/addRowSelect"](item.id);
            this["moduleInventory/setEquipmentsCopy"]([
              ...this.equipmentsCopy,
              item.EquipmentID,
            ]);
            await navigator.clipboard.writeText(this.equipmentsCopy);
          } else {
            let index = this.selectedRows.indexOf(item.id);
            if (index >= 0) this["moduleInventory/removeRowSelect"](index);
          }
        }
      }
    },
    multiCheckRow(index) {
      this.flagAllSelect = false;
      let items =
        this.tabTitle === "On Hand" ? this.inventoryDataFilteredSellStatus : this.items;
      let start, end;
      if (index > this.lastCheckIndex) {
        start = this.lastCheckIndex;
        end = index;
      } else {
        end = this.lastCheckIndex;
        start = index;
      }
      let selected = !items[index].select;

      for (let i = start; i <= end; i++) {
        if (this.isNotSOLDOrEXPIRE(items[i])) {
          items[i].select = selected;
          if (selected) {
            if (!this.selectedRows.includes(items[i].id)) {
              this["moduleInventory/addRowSelect"](items[i].id);
            }
          } else {
            let index = this.selectedRows.indexOf(items[i].id);
            if (index >= 0) this["moduleInventory/removeRowSelect"](index);
          }
        }
      }

      this["moduleInventory/setLastCheckIndex"]({
        name: this.tabTitle,
        value: index,
      });
    },
    showInboundStatus(item) {
      this["moduleInventory/showInboundStatus"](item);
    },
    generateHeader() {
      this.headerTable = [];
      for (let { value, title } of this.header) {
        this.headerTable.push({
          text: title,
          align: "center",
          sortable: value == "All" ? false : true,
          value,
          class: "table-header pa-0",
          width:
            value == "All"
              ? "5px"
              : value === "isFlaggedEnabled"
              ? "0px"
              : value == "LastModifiedDate"
              ? "145px"
              : value == "BuyerParty"
              ? "167px"
              : value == "Age"
              ? "80px"
              : value == "EquipmentID"
              ? "145px"
              : value == "LeaseAgreement"
              ? "155px"
              : "120px",
          multiSortEffect: false,
        });
      }
    },
    selectHeader(index) {
      this.headerTable[index].sortable = false;
      if (this.selectedHeader["first"].length) {
        this.selectedHeader["last"] = `${index}`;
      } else {
        this.selectedHeader["first"] = `${index}`;
      }

      if (this.selectedHeader["last"].length) {
        this.switchHeader();
      }
    },
    switchHeader() {
      let fistValue = this.headerTable[this.selectedHeader["first"]];
      let first = this.selectedHeader["first"];
      let last = this.selectedHeader["last"];
      this.$set(this.headerTable, this.selectedHeader["first"], {
        ...this.headerTable[this.selectedHeader["last"]],
      });
      this.$set(this.headerTable, this.selectedHeader["last"], {
        ...fistValue,
      });
      this.headerTable[first].sortable = true;
      this.headerTable[last].sortable = true;
      // this.dispatchSortColumn();
      // this.selectedHeader = {
      //   first: "",
      //   last: "",
      // };
      // this.headerTable[first].sortable = true;
      // this.headerTable[last].sortable = true;
      // console.log("D");
    },
    //rows
    itemChanges({ id, add }) {
      if (add) {
        if (!this.changedItems.includes(id)) this.addChangedItem(id);
      } else {
        if (this.changedItems.includes(id)) {
          let index = this.changedItems.indexOf(id);
          this.removeChangeItem(index);
        }
      }
    },
    clickColumn({ item }) {
      const indexExpanded = this.expandedData.findIndex(
        (i) => i.EquipmentID === item.EquipmentID
      );

      if (indexExpanded > -1) {
        this.expandedData.splice(indexExpanded, 1);
      } else {
        this.expandedData.push(item);
      }
    },
    dispatchSortColumn(params) {
      if (this.selectedHeader["last"].length) {
        this.selectedHeader = {
          first: "",
          last: "",
        };
      } else if (params) {
        let { sortBy, sortDesc } = params;
        this.sortColumn({ sortBy, sortDesc });
      }
    },
  },
};
</script>
